<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>기프트 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="title">고객명</option>
          <option value="agency">브랜드명</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one collection">
      <h2 class="bold">
        기프트 리스트
        <button class="right basic">
          <router-link to="/admin/gift/register">투어 기프트 등록</router-link>
        </button>
      </h2>
      <table>
        <colgroup>
          <col style="width: 8%" />
          <col style="width: 15%" />
          <col style="width: 20%" />
          <col style="width: 10%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>타입</th>
          <th>제목</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in giftList" :key="i">
          <td>
            <router-link to @click.native="handleMove(data._id)">
              {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</router-link
            >
          </td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{
              data.category == "consult"
                ? "상담"
                : data.category == "contract"
                ? "계약"
                : ""
            }}</router-link>
          </td>
          <td>
            {{ data.title }}
          </td>

          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="giftList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchGiftList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      giftList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "13-2");
  },
  mounted() {
    this.getGiftList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.giftList = [];
      this.total = 0;
      this.getGiftList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getGiftList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGiftList();
    },
    handleMove(id) {
      this.$router.push({ name: "giftRegister", query: { id: id } });
    },
    getGiftList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
      };
      fetchGiftList(params).then((res) => {
        if (res.data.status == 200) {
          this.giftList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
